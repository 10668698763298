import React, { useEffect, useState, useRef, FC, useCallback } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Slider, Popover, message } from "antd";
import { screenCapture as downloadImg } from "./utils/download";
import ForwardContent from "./components/forwardContent";
import IconFont from "components/ailiIcon";
import classnames from "classnames";
import { timeFormat } from "util/comm";
import { cloneDeep, isEqual } from "lodash-es";
import { videoProps, VideoState, videoTypes } from "./interface";
import { useToggle, useFullscreen } from "react-use";
import useSetState from "util/useSetState";
import FlvVideo from "./index";
import "./index.less";
import ThirdPartyPlayer from "./videoForVideoMonitor";
import { set } from "ol/transform";

/**
 * 对单个播放器的封装
 * @param props
 * @constructor
 */
const Player: FC<videoProps> = (props: videoProps) => {
  const {
    className,
    vehicleNo,
    url,
    passageway,
    passagewayIntl,
    sim,
    playbackTime,
    hasAudio,
    hasVideo,
    showTool,
    playbackTimeChange,
    cameraName,
    /** 只播放音频 */
    onlyPlayAudio = false,
    //视频索引
    keyNo = 0,
    //视频索引==激活
    activeIndex = 0,
  } = props;

  const videoContainer: any = useRef(null);
  const videoElement: any = useRef();
  const [show, toggle] = useToggle(false);
  const isFullscreen = useFullscreen(videoContainer, show, {
    onClose: () => toggle(false),
  });
  const timer: any = useRef();
  /** 时间差 */
  const speedDifference = useRef<number>(0);
  /** 是否拖拽进度条 */
  const isDrag = useRef<boolean>(false);
  const playStatusRef = useRef(0);
  const speedRef = useRef(1);

  const [selfUrl, setSelfUrl] = useState();

  const MyState: VideoState = {
    maskStatus: true,
    loading: false,
    videoType: videoTypes.broadcast,
    // 默认开始的音量都为0
    voice: keyNo === activeIndex ? 0 : 0,
    playTime: "00:00",
    currentProgress: 0,
    videoCurrentTime: 0,
    speed: 1,
    speedStatus: true,
    playStatus: 0,
    timer: null,
  };

  const [state, setState] = useSetState<VideoState>(MyState);
  useEffect(() => {
    // 默认开始的音量都为0
    setState({ voice: keyNo === activeIndex ? 0 : 0 });
  }, [activeIndex]);

  useEffect(() => {
    return () => {
      destroy();
    };
  }, []);

  useEffect(() => {
    if (props.url) {
      createTimout();
      setState({
        maskStatus: true,
      });
      if (props.type === "playback") {
        initPlayBackStatus();
      }
    } else {
      cleanTimout();
      setState({
        maskStatus: true,
        loading: false,
        playStatus: 0,
      });
      playStatusRef.current = 0;
    }
    // 以下是针对三方视频的
    if (cameraName) {
      if (props.url) {
        console.log("play:", props.url);
        setSelfUrl(props.url);
        play();
      } else {
        console.log("stop:", props.url);
        setState({
          maskStatus: true,
        });
        destroy();
      }
    }
  }, [props.url]);

  /**
   * 获取屏幕比
   */
  const getScrren = (value: number | undefined) => {
    if (value === 2) {
      return "video_3_4";
    } else if (value === 3) {
      return "video_16_9";
    } else {
      return "video_1_1";
    }
  };

  /**
   * 播放按钮事件
   */
  const play = useCallback(() => {
    // 只针对第三方视频
    if (cameraName && !props.url) {
      const cloneVideoList = cloneDeep(props.state.videoList);
      props.setVideoList({
        videoList: cloneVideoList.map((item: any) => {
          return item?.cameraName === props?.cameraName
            ? { ...item, url: selfUrl }
            : item;
        }),
      });
    }
    // end

    if (state.playStatus !== 1) {
      if (props.mediaType === "mp4") {
        setState({
          playStatus: 1,
        });
        playStatusRef.current = 1;
        videoElement.current.play();
      }
      if (props.onPlay) {
        props.onPlay({
          passageway: props.passageway,
          vehicleNo: props.vehicleNo,
          simNo: props.sim,
        });
      }
    }
  }, [state.playStatus, props]);

  /**
   * 销毁视频
   */
  const destroy = useCallback(() => {
    setState({
      playStatus: 0,
      playTime: "00:00",
      speed: 1,
      speedStatus: true,
    });
    playStatusRef.current = 0;
    speedRef.current = 1;
    if (props.mediaType === "mp4") {
      videoElement.current.pause();
      setState({
        loading: false,
        maskStatus: true,
      });
      if (cameraName) {
        const cloneVideoList = cloneDeep(props.state.videoList);
        props.setVideoList({
          videoList: cloneVideoList.map((item: any) => {
            return item?.cameraName === props?.cameraName
              ? { ...item, url: "" }
              : item;
          }),
        });
      }
    } else {
      onDestroy();
    }
  }, [props]);

  /**
   * 截屏
   */
  const screenCapture = () => {
    if (videoElement.current) {
      downloadImg(videoElement.current.dom, sim);
    }
  };

  const onAfterChange = useCallback(
    (data: number) => {
      if (playbackTimeChange) {
        playbackTimeChange(data);
      }
      speedDifference.current = data - state.videoCurrentTime;
      isDrag.current = false;
    },
    [state.videoCurrentTime]
  );

  const SliderOnChange = (data: number) => {
    videoElement.current.clean();
    isDrag.current = true;
    setState({
      currentProgress: data,
    });
  };

  /** 进度条时间显示格式化 */
  const tipFormatter = (value: number) => {
    return timeFormat(value);
  };

  /** 回放播放/暂停按钮 */
  const pbPlay = useCallback(() => {
    if (props.url) {
      let playStatus = state.playStatus;
      if (playStatus === 2) {
        videoElement.current.play();
        if (props.onPlay) {
          props.onPlay();
        }
        playStatus = 1;
      } else {
        videoElement.current.pause();
        onPause();
        playStatus = 2;
      }
      playStatusRef.current = playStatus;
      setState({
        playStatus,
      });
    }
  }, [state.playStatus, props.url]);

  /**
   * 快进
   * @param value
   */
  const fastForward = (data: any) => {
    if (props.onFastForward) {
      props.onFastForward(data);
    }
    speedRef.current = data;
    setState({
      speed: data,
      speedStatus: true,
    });
  };

  /**
   * 快退
   * @param value
   */
  const goBack = (data: any) => {
    if (props.onGoBack) {
      props.onGoBack(data);
    }
    speedRef.current = data;
    setState({
      speed: data,
      speedStatus: false,
    });
  };

  /**
   * 音量改变
   */
  const voiceChange = (value: number) => {
    if (props.voiceChange) {
      props.voiceChange(value, keyNo);
    }
    setState({ voice: value });
  };

  const onStart = () => {
    cleanTimout();
    playStatusRef.current = 1;
    setState({
      playStatus: 1,
      maskStatus: false,
      loading: false,
    });
  };

  const onDestroy = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    speedRef.current = 1;
    if (props.destroyClick) {
      // TODO 这个注释应该放开，否则播放异常的时候不会主动关闭异常的视频
      props.destroyClick(url);
    }

    setState({
      loading: false,
      maskStatus: true,
    });
  };

  const onPause = () => {
    if (props.onPause) {
      props.onPause();
    }
  };

  const onEnd = () => {
    if (props.onEnded) {
      props.onEnded();
    }
  };

  const initPlayBackStatus = () => {
    speedDifference.current = 0;
    playStatusRef.current = 1;
    setState({
      currentProgress: 0,
      playTime: "00:00",
      videoCurrentTime: 0,
      speed: 1,
      speedStatus: true,
      playStatus: 1,
    });
  };

  /** 计算进度条 */
  const onPlayChange = useCallback(
    (currentTime: number) => {
      if (props.type === "playback") {
        let obj: any = {};

        const speed: number = handlePlayTime(
          currentTime,
          speedDifference.current
        );
        // 获取当前速率
        let selfSpeed: number = 0;
        console.log(state.speedStatus, speedRef.current);
        if (state.speedStatus) {
          selfSpeed = speedRef.current === 1 ? 0 : speedRef.current - 1;
        } else {
          selfSpeed = -speedRef.current - 1;
        }
        obj = {
          playTime: timeFormat(
            Math.floor(
              speed > playbackTime! ? playbackTime! : speed + selfSpeed
            )
          ),
        };
        speedDifference.current += selfSpeed;

        if (!isDrag.current) {
          obj.currentProgress = speed + selfSpeed;
        }
        // 播放时间大于等于结束时间，关闭视频
        if (
          (speed >= playbackTime! || speed <= 0) &&
          playStatusRef.current === 1
        ) {
          videoElement.current.pause();
          playStatusRef.current = 0;
          onEnd();
          obj.playStatus = 1;
        }

        setState({
          videoCurrentTime: currentTime,
          ...obj,
        });
      }
    },
    [state.videoCurrentTime, state.speedStatus, playbackTime]
  );

  const onInstanceCreated = () => {
    createTimout();
    setState({ loading: true });
  };

  /** 创建计时器 */
  const createTimout = () => {
    cleanTimout();
    if (!timer.current) {
      timer.current = setTimeout(() => {
        cleanTimout();
        destroy();
        if (props.onEnded) {
          props.onEnded();
        }
        if (props.isTimeoutTip !== false) {
          // 中英文，提示由外部传入
          // message.warning({
          //   content: props.timeoutMessage || "播放异常，请重试",
          //   key: 91,
          // });
        }
      }, 20000);
    }
  };

  /** 清除计时器 */
  const cleanTimout = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
      timer.current = null;
    }
  };

  /**
   * 计算准确的进度时间
   * @param videoCurrentTime
   */
  function handlePlayTime(videoCurrentTime: number, speedDifference: number) {
    const speed: number = videoCurrentTime + speedDifference;
    return speed;
  }
  return (
    <div
      className={classnames("video", "video-container", className)}
      ref={videoContainer}
    >
      <div className="container">
        {cameraName ? (
          <>
            <div
              className={classnames("mask", getScrren(props.videoScale || 1))}
              style={{ display: "block" }}
            />
            <div
              className={classnames(
                "loading",
                getScrren(props.videoScale || 1)
              )}
              style={{ display: "flex" }}
            >
              <IconFont
                type="iconloading"
                className="loadingIcon"
                style={{ color: "#fff" }}
              />
            </div>
            <ThirdPartyPlayer
              ref={videoElement}
              className={classnames(
                "content",
                getScrren(props.videoScale || 1)
              )}
              hasAudio={hasAudio}
              hasVideo={hasVideo}
              url={state.playStatus === 0 ? "" : props.url}
              voice={state.voice}
              onStart={onStart}
              onDestroy={onDestroy}
              onPause={onPause}
              onPlayTimeChange={onPlayChange}
              onInstanceCreated={onInstanceCreated}
              onEnded={props.onEnded}
              isSplit={props.isSplit === false ? false : true}
              type={props.mediaType}
              videoAttribute={props.videoAttribute}
            />
          </>
        ) : (
          <FlvVideo
            ref={videoElement}
            className={classnames("content", getScrren(props.videoScale || 1))}
            hasAudio={hasAudio}
            hasVideo={hasVideo}
            url={props.url}
            voice={state.voice}
            onStart={onStart}
            onDestroy={onDestroy}
            onPause={onPause}
            onPlayTimeChange={onPlayChange}
            onInstanceCreated={onInstanceCreated}
            onEnded={props.onEnded}
            isSplit={props.isSplit === false ? false : true}
            type={props.mediaType}
            videoAttribute={props.videoAttribute}
            isDelay={props?.isDelay}
          />
        )}

        {/* 只播放音频的时候 遮盖层不去除 */}
        <div
          className={classnames("mask", getScrren(props.videoScale || 1))}
          style={{
            display: state.maskStatus ? "block" : "none",
            zIndex: 4,
          }}
        ></div>

        <div
          className={classnames("mask_audio", getScrren(props.videoScale || 1))}
          style={{
            display: !state.maskStatus && onlyPlayAudio ? "block" : "none",
            zIndex: 4,
          }}
        ></div>
        <div
          className={classnames("loading", getScrren(props.videoScale || 1))}
          style={{ display: state.loading ? "flex" : "none", zIndex: 4 }}
        >
          <IconFont
            type="iconloading"
            className="loadingIcon"
            style={{ color: "#fff" }}
          />
        </div>
      </div>
      {showTool !== false ? (
        <>
          {props.type === videoTypes.broadcast ? (
            <div className="toolbar">
              <div className="toobar-left">
                {props.showPlay !== false ? (
                  <IconFont type="iconplay" className="icon" onClick={play} />
                ) : null}
                {props.showStop !== false ? (
                  <IconFont
                    type="iconstop"
                    className="icon"
                    onClick={destroy}
                  />
                ) : null}
              </div>
              <div className="toobar-center">
                <span className="text text-no-select">{vehicleNo}</span>
                <span className="text text-no-select">
                  {cameraName
                    ? props.cameraName
                    : `${passagewayIntl || "通道"} ${passageway || ""}`}
                </span>
              </div>
              <div className="toobar-right">
                {props.showVoice !== false ? (
                  <Popover
                    placement="top"
                    content={
                      <Slider
                        vertical={true}
                        value={state.voice}
                        style={{ height: 150 }}
                        onChange={(value: number) => {
                          voiceChange(value);
                        }}
                      />
                    }
                  >
                    {state.voice > 0 ? (
                      <IconFont
                        type="iconvoice"
                        className="icon"
                        onClick={() => {
                          voiceChange(0);
                        }}
                      />
                    ) : (
                      <IconFont
                        type="iconmute"
                        className="icon"
                        onClick={() => {
                          voiceChange(80);
                        }}
                      />
                    )}
                  </Popover>
                ) : null}

                {props.showCapture !== false ? (
                  <IconFont
                    type="iconscreenshots"
                    className="icon"
                    onClick={screenCapture}
                  />
                ) : null}
                {!isFullscreen ? (
                  <IconFont
                    type="iconfull-screen"
                    className="icon"
                    onClick={toggle}
                  />
                ) : (
                  <IconFont
                    type="iconun-full-screen"
                    className="icon"
                    onClick={toggle}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="playbackToolbar">
              <div className="toobar-left">
                <span className="text text-no-select">{vehicleNo || ""}</span>
                <span className="text text-no-select">
                  {cameraName
                    ? props.cameraName
                    : passageway
                    ? `${passagewayIntl || "通道"} ${passageway || ""}`
                    : ""}
                </span>
              </div>
              {/* 进度条 */}
              <div className="toobar-center">
                <span>{state.playTime}</span>
                <Slider
                  className="progress-bar"
                  value={state.currentProgress}
                  onAfterChange={onAfterChange}
                  onChange={SliderOnChange}
                  tipFormatter={tipFormatter}
                  disabled={!playbackTime}
                  min={0}
                  max={playbackTime}
                />
                <span>{timeFormat(playbackTime)}</span>
              </div>
              <div className="toobar-right">
                {/* 暂停 */}
                <IconFont
                  type={state.playStatus === 1 ? "iconpause" : "iconbofang"}
                  onClick={pbPlay}
                  className="icon"
                />
                {/* 快进 */}
                <Popover
                  placement="top"
                  content={
                    <ForwardContent
                      active={state.speedStatus}
                      value={state.speed}
                      onClick={fastForward}
                    />
                  }
                >
                  <IconFont type="iconfast-forward" className="icon" />
                </Popover>
                {/* 快退 */}
                <Popover
                  placement="top"
                  content={
                    <ForwardContent
                      active={!state.speedStatus}
                      value={state.speed}
                      onClick={goBack}
                    />
                  }
                >
                  <IconFont type="iconfast-rewind" className="icon" />
                </Popover>
                {/* 关闭视频 */}
                <CloseCircleFilled className="icon" onClick={destroy} />

                <Popover
                  placement="top"
                  content={
                    <Slider
                      vertical={true}
                      value={state.voice}
                      style={{ height: 150 }}
                      onChange={(value: number) => {
                        voiceChange(value);
                      }}
                    />
                  }
                >
                  {state.voice > 0 ? (
                    <IconFont
                      type="iconvoice"
                      className="icon"
                      onClick={() => {
                        voiceChange(0);
                      }}
                    />
                  ) : (
                    <IconFont
                      type="iconmute"
                      className="icon"
                      onClick={() => {
                        voiceChange(80);
                      }}
                    />
                  )}
                </Popover>
                <IconFont
                  type="iconscreenshots"
                  onClick={screenCapture}
                  className="icon"
                />
                {!isFullscreen ? (
                  <IconFont
                    type="iconfull-screen"
                    onClick={toggle}
                    className="icon"
                  />
                ) : (
                  <IconFont
                    type="iconun-full-screen"
                    onClick={toggle}
                    className="icon"
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default React.memo(Player, isEqual);
