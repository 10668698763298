import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import classnames from "classnames";
import Flv from "flv.js";
import { baseProps } from "./interface";
import moment from "moment";

/**
 * 对原生video标签的封装，暴露play pause clean三个方法和video标签对象到外部
 * @param props {baseProps}
 * @param ref
 * @constructor
 */
const Video = (props: baseProps, ref: any) => {
  const videoElement: any = useRef(null);
  const RTCPlayer: any = useRef(null);

  const [url, setUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    initFun();
  }, [props.url]);

  function initFun(isLoading = true) {
    if (props.url) {
      initPlayer(props.url, isLoading);
    } else if (RTCPlayer.current) {
      destroy();
    }
  }

  useEffect(() => {
    if (props.onPlayTimeChange) {
      props.onPlayTimeChange(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    voiceChange(props.voice);
  }, [props.voice]);

  useImperativeHandle(ref, () => {
    return {
      play: play,
      pause: pause,
      clean: cleanChunnk,
      dom: videoElement.current,
    };
  });

  /**
   * 初始化播放器
   * 根据props.type判断采用flv.js还是用原生video标签播放
   * @param selfurl
   */
  const initPlayer = async (selfurl: string, isLoading = true) => {
    await destroyEvent();
    videoElement.current.oncanplay = play;
    videoElement.current.onended = ended;
    videoElement.current.addEventListener("timeupdate", events);
    if (props.type === "flv") {
      RTCPlayer.current = await createRTCPlayer(isLoading);
    } else {
      let _urlarr = url ? url.split("t=") : "";
      let _selfurlarr = selfurl ? selfurl.split("t=") : "";
      if (_urlarr && _urlarr[0] === _selfurlarr[0]) {
        videoElement.current.currentTime = 0;
        videoElement.current.play();
      } else {
        setUrl(selfurl || "");
      }
    }
  };

  const play = () => {
    if (props.onStart) {
      props.onStart();
    }
    palyEvent();
  };

  const ended = () => {
    if (props.onEnded) {
      props.onEnded();
    }
  };

  const pause = () => {
    if (videoElement.current) {
      videoElement.current.pause();
    }
    // if (RTCPlayer.current) {
    //   console.warn("1销毁rtc播放");
    //   RTCPlayer.current.close();
    // }
  };

  const destroy = () => {
    // if (props.onDestroy) {
    //     props.onDestroy()
    // }
    destroyEvent();
  };

  const cleanChunnk = () => {
    // if (RTCPlayer.current) {
    //   let end = RTCPlayer.current.buffered.end(0);
    //   RTCPlayer.current.currentTime = end - 0.2;
    // }
  };

  const voiceChange = (data: any) => {
    if (videoElement.current) {
      videoElement.current.volume = Number(data) / 100;
    }
  };

  const palyEvent = () => {
    if (videoElement.current) {
      videoElement.current.play();
    }
  };

  const destroyEvent = () => {
    if (RTCPlayer.current) {
      console.warn("2销毁rtc播放");
      // RTCPlayer.current.pause();
      // RTCPlayer.current.unload();
      // RTCPlayer.current.detachMediaElement();
      RTCPlayer.current.close();
      RTCPlayer.current = null;
      videoElement.current.oncanplay = null;
      videoElement.current.removeEventListener("timeupdate", events);
    }
  };

  function GetQueryString(url: string, name: string, default_value: any) {
    //获取超链接数据
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let uirval = url.split("?");
    var r = uirval[1].substr(0).match(reg);
    if (r != null) return r[2];
    return default_value;
  }
  /**
   * 创建flvPlayer对象
   *
   * 更新： 由于这个方法在唯一被调用的地方调用前，判断过props.type===‘flv'之后才调用
   * 所以这里移除掉对type的判断，简化代码
   */
  const createRTCPlayer = async (
    isLoading: boolean
  ): Promise<Flv.Player | null> => {
    const { url, hasAudio, hasVideo, isLive, isSplit } = props;
    if (url) {
      let selfUrl = url;

      // let zz=GetQueryString(selfUrl,'stream',null)

      let sleep = (delaytime = 1000) => {
        return new Promise((resolve) => setTimeout(resolve, delaytime));
      };

      await sleep();

      const RTCPlayer = new window.ZLMRTCClient.Endpoint({
        element: videoElement.current, // video 标签
        debug: true, // 是否打印日志
        // zlmsdpUrl: `https://139.224.118.234/index/api/webrtc?app=rtp&stream=${zz}&type=play`, //流地址
        zlmsdpUrl: `${selfUrl}`, //流地址
        simulcast: false,
        useCamera: false,
        audioEnable: true,
        videoEnable: true,
        recvOnly: true,
        // resolution:{w:w,h:h},
        usedatachannel: false,
      });
      if (isLoading) {
        if (props.onInstanceCreated) {
          props.onInstanceCreated(RTCPlayer);
        }
      }

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
        function (_e: any) {
          // ICE 协商出错
          console.log("ICE 协商出错");
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
        function (e: any) {
          //获取到了远端流，可以播放
          console.log("rtc datachannel 播放成功", e.streams);
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
        function (e: any) {
          // offer anwser 交换失败
          console.log("offer anwser 交换失败", e);
          // stop();
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,
        function (_s: any) {
          // 获取到了本地流
          // document.getElementById("selfVideo").srcObject = s;
          // document.getElementById("selfVideo").muted = true;
          //console.log('offer anwser 交换失败',e)
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.CAPTURE_STREAM_FAILED,
        function (_s: any) {
          // 获取本地流失败
          console.log("rtc datachannel 获取本地流失败");
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,
        function (state: any) {
          // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
          console.log("rtc datachannel 当前状态==>", state);
          //错误重连
          if (state === "failed" || state === "disconnected") {
            console.error(
              "rtc datachannel 错误状态==>",
              state,
              props.url,
              moment().format("YYYY-MM-DD HH:mm:ss")
            );
            initFun(false);
          }
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,
        function (event: any) {
          console.log("rtc datachannel 打开 :", event);
        }
      );

      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,
        function (event: any) {
          console.log("rtc datachannel 消息 :", event.data);
          // document.getElementById("msgrecv").value = event.data;
        }
      );
      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,
        function (event: any) {
          console.log("rtc datachannel 错误 :", event);
        }
      );
      RTCPlayer.on(
        window.ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,
        function (event: any) {
          console.log("rtc datachannel 关闭 :", event);
        }
      );

      // const RTCPlayer: Flv.Player = Flv.createPlayer(
      //   {
      //     type: "flv",
      //     url: selfUrl,
      //     hasAudio: hasAudio || false,
      //     hasVideo: hasVideo !== false,
      //     isLive: isLive !== false,
      //     withCredentials: false,
      //   },
      //   {
      //     /** 默认缓存180kb 开始播放视频 */
      //     stashInitialSize: 180,
      //   }
      // );
      // if (props.onInstanceCreated) {
      //   props.onInstanceCreated(RTCPlayer);
      // }
      // if (props?.isDelay) {
      //   //增加延迟
      //   let sleep = (delaytime = 3000) => {
      //     return new Promise((resolve) => setTimeout(resolve, delaytime));
      //   };
      //   await sleep();
      // }
      // RTCPlayer.attachMediaElement(videoElement.current);

      // // 监听错误
      // RTCPlayer.on(Flv.Events.ERROR, async (_eType, _b, c) => {
      //   if (c?.code === 404) {
      //     // RTCPlayer.pause();
      //     // RTCPlayer.unload();
      //     // RTCPlayer.load();
      //     // console.log("+重新加载+");
      //   }
      // });

      // RTCPlayer.load();

      return RTCPlayer;
    }
    return null;
  };

  // 视频播放监听事件
  const events = () => {
    if (videoElement.current) {
      // 获取整数
      setCurrentTime(~~videoElement.current.currentTime);
    }
  };

  return (
    <>
      <video
        ref={videoElement}
        className={classnames("flv-video", props.className)}
        src={url}
      ></video>
    </>
  );
};

export default forwardRef(Video);
