import React, { useEffect, useState, useCallback } from 'react'
import RealTimeVehicle from './concreteFC/realTimeVehicle'
import RealTimeDelivery from './concreteFC/RealTimeDelivery'
import RealTimeVolume from './concreteFC/RealTimeVolume'
import RealTimeWaybill from './concreteFC/RealTimeWaybill'
import Map from './concreteFC/map';
import Video from './video';
import AlarmVehicle from './alarmVehicle';
import RealTime from './realTime';
import { useIntl } from 'react-intl';
import { webAPIResponse } from 'server/web/index.globals'
import useSetState from 'util/useSetState'
import { useInterval } from 'react-use';
import { videoItem } from 'components/video/interface';
import { findRealDataBy, findWaybillRealBy, findSupplyRealBy, findVideoLinkChannelBy, queryAlarmVideo } from 'server/web/screen'
import { getAdvertisement } from 'server/web/systemfile'

interface MyState extends Partial<webAPIResponse.findRealDataBy> {
  time: number
  /** 播放下标 */
  playVehicleId: number
  /** 播放轮询 */
  playRounds: number
  videoList: Array<videoItem>
}

/** 是否第一次请求播放 */
// let firstPlay: boolean = false

function Concrete() {

  const baseUrl: string = process.env.REACT_APP_BASEURL!
  // const baseUrl: string = 'https://tx2-test.aidriving.com'

  const [state, setState] = useSetState<MyState>({
    time: 0,
    playVehicleId: 0,
    playRounds: 1,
    videoList: []
  })

  const [waybill, setWaybill] = useState<webAPIResponse.findWaybillRealBy[]>([])
  const [supply, setSupply] = useState<any[]>([])
  /** 广告视频 */
  const [adList, setAdList] = useState<Array<videoItem>>([])

  /** 视频 */
  const [videoList, setVideoList] = useState<Array<videoItem>>([])
  /** 是否为第一批 */
  const [isFirst, setIsFirst] = useState(true)

  const { formatMessage: f } = useIntl()

  useEffect(() => {
    queryData()
    // queryFindWaybillRealBy()
    querySupply()
    initQueryData()
  }, [])

  useInterval(() => {
    queryData()
    // queryFindWaybillRealBy()
    querySupply()
  }, 10000)

  //  视频轮询
  // useInterval(() => {
  //   if (waybill) {
  //     queryVehicleVideo(waybill)
  //   }
  // }, state.time ? 60000 : null)


  // const queryVehicleVideo = useCallback(async (data: Array<{
  //   plateNo: string
  //   vehicleId: string
  // }>) => {
  //   if (state.playRounds === 5) {
  //     setState({ time: 0 })
  //   } else {
  //     const vehicle = data![state.playVehicleId]
  //     if (!vehicle) {
  //       return;
  //     }
  //     setState({
  //       time: 0
  //     })
  //     const vResult = await findVideoLinkChannelBy({
  //       mediaType: '0',
  //       streamType: '0',
  //       vehicleId: vehicle.vehicleId
  //     })

  //     if (vResult && vResult.data && vResult.data.videoLinks) {
  //       setState({
  //         videoList: vResult.data.videoLinks.map(item => {
  //           return {
  //             passageway: item.passageway,
  //             url: item.url + '?t=' + (new Date()).valueOf(),
  //             sim: vResult.data.sim,
  //             vehicleNo: vResult.data.vehicleNo,
  //             sessionId: vResult.data.sessionId,
  //           }
  //         })
  //       })
  //     }

  //     if (state.playVehicleId >= data!.length) {
  //       setState({
  //         playRounds: state.playRounds + 1,
  //         playVehicleId: 0
  //       })
  //     }

  //     setState({
  //       time: 60000,
  //       playVehicleId: state.playVehicleId + 1
  //     })

  //   }
  // }, [state.playVehicleId, state.playRounds])

  const queryData = async () => {
    const result = await findRealDataBy()
    if (result) {
      setState(result.data)
    }
  }

  /** 实时供货数据 */
  const querySupply = async () => {
    const result = await findSupplyRealBy()
    if (result) {
      setSupply(result.data)
    }
  }

  /** 实时运单数据 */
  // const queryFindWaybillRealBy = async () => {
  //   const result = await findWaybillRealBy()
  //   if (result) {
  //     setWaybill(result.data)
  //     if (!firstPlay) {
  //       queryVehicleVideo(result.data)
  //       firstPlay = true
  //     }
  //   }
  // }

  const initQueryData = async () => {
    let adVideoList: Array<videoItem> = []
    let alarmVideoList: Array<videoItem> = []
    setIsFirst(true)
    const result = await getAdvertisement()
    if (result) {
      adVideoList = result.data.map((item, index) => {
        return {
          passageway: 0,
          url: item.path,
          sim: 'ad_' + index,
          vehicleNo: '',
          sessionId: 'ad_session'
        }
      })
      setAdList(adVideoList)
    }

    const alarmResult = await queryAlarmVideo()
    if (alarmResult) {
      alarmVideoList = alarmResult.data.map((item, index) => {
        return {
          passageway: 0,
          url: baseUrl + '/api/webapi/filenamager/getFileByPath?path=' + item.filePath + '&t=' + new Date().getTime(),
          sim: 'alarm_' + index,
          vehicleNo: item.plateNo,
          sessionId: 'alarm_session'
        }
      })
    }

    handleVideoList(alarmVideoList, adVideoList)
  }


  /** 获取报警视频 */
  const queryAlarm = useCallback(async () => {
    setIsFirst(true)
    const result = await queryAlarmVideo()
    if (result) {
      const arr = result.data.map((item, index) => {
        return {
          passageway: 0,
          url: baseUrl + '/api/webapi/filenamager/getFileByPath?path=' + item.filePath + '&t=' + new Date().getTime(),
          sim: 'alarm_' + index,
          vehicleNo: item.plateNo,
          sessionId: 'alarm_session'
        }
      })

      handleVideoList(arr, adList)
    }
  }, [adList])

  /** 处理视频列表
   * @param index 第几批播放
  */
  const handleVideoList = (alarmList: Array<videoItem>, adList: Array<videoItem>) => {
    const MaxList: number = alarmList.length < 4 ? 4 : 8
    const ADMax: number = MaxList - alarmList.length

    const ADList = adList.slice(0, ADMax)
    const newVideoList = [...alarmList, ...ADList.map(item => ({ ...item, url: item.url + '?t=' + new Date().getTime() }))]

    setVideoList(newVideoList)
    setState({
      videoList: newVideoList.slice(0, 4)
    })
  }

  /** 全部视频播放完成 */
  const onAllEnded = useCallback(() => {
    if (videoList.length > 4 && isFirst) {
      setTimeout(() => {
        setState({
          videoList: videoList.splice(4, 8)
        })
      }, 1000)
      setIsFirst(false)
    } else {
      setTimeout(() => {
        queryAlarm()
      }, 1000)
    }
  }, [videoList, isFirst])

  return (
    <div className='tx-large'>
      <div className='concrete-large'>
        <div className='tx-large-left'>
          {/* 实时车辆 */ }
          <RealTimeVehicle
            dispatchingNum={ state.dispatchingNum }
            restNum={ state.restNum }
            serviceNum={ state.serviceNum }
            vehicleNum={ state.vehicleNum }
            walkthroughNum={ state.walkthroughNum }
          />
          {/* 实时供货*/ }
          <RealTimeDelivery
            data={ supply }
          />
          {/* 实时方量  */ }
          <RealTimeVolume
            planTotalVolumen={ state.planTotalVolumen }
            carriageTtotalVolumen={ state.carriageTtotalVolumen }
            accomplishTotalVolumen={ state.accomplishTotalVolumen }
          />
        </div>
        <div className='tx-large-right'>
          <div className='large-right-top'>
            <div className='large-right-top-title'>
              <div className='top-title-icon left-icon'></div>
              <div className='top-title-name'>{ f({ id: 'tx000140', description: '商砼大数据' }) }</div>
              <div className='top-title-icon right-icon'></div>
            </div>
            <RealTime />
          </div>
          <div className='large-right-bottom'>
            <Map />
            <Video
              videoList={ state.videoList }
              onAllEnded={ onAllEnded }
            />
          </div>
          {/* 实时运货单 */ }
          {/* <RealTimeWaybill
            data={ waybill }
          /> */}
          {/* 实时报警车辆 */ }
          {/* <AlarmVehicle /> */ }
        </div>
      </div>
    </div>
  )
}

export default Concrete
