import React, {FC, useState, useEffect, useRef} from 'react'
import {useSetState} from 'react-use'
import {Button} from 'antd'
import {FormInstance} from 'antd/lib/form'
import {PlusOutlined} from '@ant-design/icons'
import {Modal, Form, SelectCode, DatePicker, Table, TreeSelect} from 'components'
import {ColumnsType} from 'components/table/interface'
import {ModalProps} from '../interface'
import {useIntl} from 'react-intl'
import {videoDownload, VideoDownloadType, fileUpload, FileUpload} from 'server/web/sendCommand'
import {findByVehicleId, VideoRecording} from 'server/web/video'
import {downloadFileByFtpPath} from 'server/web/fileManager'
import {getAlarmTypeForVideoDownload} from 'server/web/basicCode'
import {useAxios, useBasicCodes, usePollingCommandStatus} from 'util/hook'
import {MyFormItemProps} from 'components/form'
import {pleaseSelect as s, handleTreeDataKey, handleTreeData} from 'util/comm'
import {tableStore} from 'store'
import moment from 'moment'
import './index.less'

const {RangePicker} = DatePicker

interface OperatingStatus {
  /** 暂停中的baseId数组 */
  baseIdsPaused: string[]
  operatingStatusName?: string
  loading?: {
    baseId: string
    controlCmd: FileUpload['controlCmd']
  }
}

const VideoDownload: FC<ModalProps> = props => {
  const {record, ...extra} = props
  const tableName = 'rightCommand_videoDownload_table'
  const {formatMessage: f} = useIntl()
  const form = useRef<FormInstance>()
  const [uploadStatusName, setUploadStatusName] = useState('')
  const [operatingStatus, setOperatingStatus] = useSetState<OperatingStatus>({baseIdsPaused: []})
  const [sendLoading, setSendLoading] = useState(false)
  const [downloadingId, setDownloadingId] = useState<string>()
  const [{data: basicCodes}] = useBasicCodes([
    'VideoChannel',
    'VideoResourceType',
    'StreamAvType',
    'StoreType',
    'UploadConditionType',
  ])

  const [, sendCommand] = useAxios({
    axios: videoDownload,
    defaultStart: false,
  })

  const [, sendFileUpload] = useAxios({
    axios: fileUpload,
    defaultStart: false,
  })

  const [{loading: downloading}, download] = useAxios({
    axios: downloadFileByFtpPath,
    defaultStart: false,
    onSuccess: (data: false | string) => {
      if (data) {
        // const fileType = data.type !== 'application/json' ? 'video/mp4' : undefined
        // fileDownload(data, fileType)
        let eleLink = document.createElement('a')
        eleLink.download = 'video'
        eleLink.style.display = 'none'
        eleLink.href = '/' + data;
        eleLink.target= '_BLANK'
        // 受浏览器安全策略的因素，动态创建的元素必须添加到浏览器后才能实施点击
        document.body.appendChild(eleLink)
        // 触发点击
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
      }
    },
  })

  const onDownload = async (columnsRecord: VideoRecording) => {
    const {filePath, baseId} = columnsRecord
    if (!filePath) return
    try {
      setDownloadingId(baseId)
      await download({ payload: { path: filePath } })
    } finally {
      setDownloadingId(undefined)
    }
  }

  const [{data: sendCommandStatus}, pollingSend] = usePollingCommandStatus()

  useEffect(() => {
    setUploadStatusName(sendCommandStatus?.statusName || '')
  }, [sendCommandStatus])

  const [{data: fileUploadCommandStatus}, pollingFileUpload] = usePollingCommandStatus()

  useEffect(() => {
    setOperatingStatus({operatingStatusName: fileUploadCommandStatus?.statusName})
  }, [fileUploadCommandStatus, setOperatingStatus])

  const onFileUpload = async (
    columnsRecord: VideoRecording,
    controlCmd: FileUpload['controlCmd'],
  ) => {
    const {vehicleId} = record!
    const {commandId, baseId} = columnsRecord
    setOperatingStatus({
      loading: {baseId, controlCmd},
      operatingStatusName: f({id: 'tx08_0', description: '等待服务器响应'}),
    })
    try {
      const _commandId = await sendFileUpload({payload: {commandId, controlCmd, vehicleId}})
      const {status} = await pollingFileUpload(_commandId)
      if (status === 'Success') {
        setOperatingStatus(state => {
          const {baseIdsPaused} = state
          const newBaseIdsPaused =
            controlCmd === 0
              ? [...baseIdsPaused, baseId]
              : controlCmd === 1
              ? baseIdsPaused.filter(item => item !== baseId)
              : baseIdsPaused
          return {baseIdsPaused: newBaseIdsPaused}
        })
      }
    } finally {
      setOperatingStatus({loading: undefined})
    }
  }

  const onSend = async () => {
    const formData = (await form.current?.validateFields()) as VideoDownloadType
    setUploadStatusName(f({id: 'tx08_0', description: '等待服务器响应'}))
    setSendLoading(true)
    try {
      const commandId = await sendCommand({
        payload: {...formData, vehicleId: record!.vehicleId},
      })
      tableStore.queryTable(tableName)
      await pollingSend(commandId)
    } finally {
      setSendLoading(false)
    }
  }

  const config: MyFormItemProps[] = [
    {
      name: 'channel',
      intlLable: 'tx070005',
      description: '通道',
      rules: [{required: true}],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.VideoChannel}
          placeholder={s({id: 'tx070005'})}
        />
      ),
    },
    {
      name: 'rangePickerTime',
      intlLable: 'tx000166',
      description: '查询时间',
      dom: <RangePicker allowClear={false} format="YYYY-MM-DD HH:mm:ss" showTime/>,
      handleValue: value => ({
        startDate: value ? value[0] : undefined,
        endDate: value ? value[1] : undefined,
        rangePickerTime: undefined,
      }),
      initialValue: [
        moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        moment().startOf('day').add(30, 'minute').format('YYYY-MM-DD HH:mm:ss'),
      ],
      row: 2,
      labelCol: {span: 5},
      wrapperCol: {span: 18},
      style: {marginLeft: -2},
      rules: [
        {required: true},
        {
          validator: (_rule, values: [string, string], callback) => {
            const [start, end] = values.map(item => moment(item).valueOf())
            /** 30分钟 = 1800000毫秒 */
            const diffTime = 1800000
            if (end - start > diffTime) {
              callback(f({id: 'tx1600051', description: '选择时间不能超过30分钟'}))
            }
            callback()
          },
        },
      ],
    },
    {
      name: 'alarmStatus',
      intlLable: 'tx020002',
      description: '报警类型',
      rules: [{required: true}],
      dom: (
        <TreeSelect
          placeholder={s({id: 'tx020002'})}
          showSearch
          treeCheckable
          maxTagCount={0}
          dropdownMatchSelectWidth={false}
          query={getAlarmTypeForVideoDownload}
          payload={{alarmSources: ['terminal_alarm', 'video_alarm']}}
          queryTime="useEffect"
          handleResult={arr =>
            handleTreeData(handleTreeDataKey(arr), {value: 'key'}).map((item: any) => {
              item.selectable = false
              return item
            })
          }
        />
      ),
    },
    {
      name: 'videoType',
      intlLable: 'tx070032',
      description: '音视频类型',
      rules: [{required: true}],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.VideoResourceType}
          placeholder={s({id: 'tx070032'})}
        />
      ),
    },
    {
      name: 'streamType',
      intlLable: 'tx070002',
      description: '码流类型',
      rules: [{required: true}],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.StreamAvType}
          placeholder={s({id: 'tx070002'})}
        />
      ),
    },
    {
      name: 'storeType',
      intlLable: 'tx070003',
      description: '存储器类型',
      rules: [{required: true}],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.StoreType}
          placeholder={s({id: 'tx070003'})}
        />
      ),
    },
    {
      name: 'taskCondition',
      intlLable: 'tx000167',
      description: '任务执行条件',
      rules: [{required: true}],
      dom: (
        <SelectCode
          defaultEchoAfterQuery
          basicCodesOption={basicCodes?.UploadConditionType}
          placeholder={s({id: 'tx000167'})}
        />
      ),
    },
  ]

  const columns: ColumnsType<VideoRecording> = [
    {
      dataIndex: 'channelId',
      intlTitle: 'tx070005',
      description: '通道',
      width: 100,
    },
    {
      dataIndex: 'startDate',
      intlTitle: 'tx000103',
      description: '开始时间',
      width: 140,
    },
    {
      dataIndex: 'endDate',
      intlTitle: 'tx000104',
      description: '结束时间',
      width: 140,
    },
    {
      dataIndex: 'alarmStatusName',
      intlTitle: 'tx020002',
      description: '报警类型',
      width: 140,
    },
    {
      dataIndex: 'dataTypeName',
      intlTitle: 'tx070032',
      description: '音视频类型',
      width: 140,
    },
    {
      dataIndex: 'streamTypeName',
      intlTitle: 'tx070002',
      description: '码流类型',
      width: 140,
    },
    {
      dataIndex: 'storeTypeName',
      intlTitle: 'tx070003',
      description: '存储器类型',
      width: 140,
    },
    {
      dataIndex: 'Action',
      intlTitle: 'tx000007',
      description: '操作',
      width: 180,
      fixed: 'right',
      render: (_text, columnsRecord) => {
        const {loading, baseIdsPaused} = operatingStatus
        const {baseId, status} = columnsRecord
        const isPaused = baseIdsPaused.includes(baseId)
        /** 文件是否上传完成，上传完成后下载按钮才能点击，并把暂停，继续，取消等操作置灰 */
        const isUploadCompleted = status === 'upload_completed'
        /** 用户是否取消文件上传，为true时所以按钮都不可操作 */
        const isUploadCanceled = status === 'upload_canceled'
        return (
          <>
            <Button
              type="link"
              size="small"
              disabled={
                isUploadCanceled ||
                isUploadCompleted ||
                (loading && (loading.controlCmd === 2 || loading.baseId !== baseId))
              }
              loading={loading && loading.baseId === baseId && loading.controlCmd !== 2}
              onClick={() => onFileUpload(columnsRecord, !isPaused ? 0 : 1)}
            >
              {f({
                id: !isPaused ? 'tx070009' : 'tx000059',
                description: '暂停/继续',
              })}
            </Button>

            <Button
              type="link"
              size="small"
              disabled={
                isUploadCanceled ||
                isUploadCompleted ||
                (loading && (loading.controlCmd !== 2 || loading.baseId !== baseId))
              }
              loading={loading && loading.baseId === baseId && loading.controlCmd === 2}
              onClick={() => onFileUpload(columnsRecord, 2)}
            >
              {f({id: 'tx000002', description: '取消'})}
            </Button>
            <Button
              type="link"
              size="small"
              disabled={
                !isUploadCompleted || isUploadCanceled || (downloading && downloadingId !== baseId)
              }
              loading={downloading && downloadingId === baseId}
              onClick={() => onDownload(columnsRecord)}
            >
              {f({id: 'tx000013', description: '下载'})}
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <Modal
      {...extra}
      title={f({id: 'tx1900302', description: '视频下载'})}
      width={1000}
      wrapClassName="right-command-video-download-modal"
      footer={false}
    >
      <Form
        size="small"
        items={config}
        ref={form}
        row={4}
        labelCol={{span: 10}}
        wrapperCol={{span: 14}}
        gutter={8}
      />
      <div className="table-box">
        <Table
          tableName={tableName}
          columns={columns}
          axios={{
            query: findByVehicleId,
            queryParam: {
              vehicleId: record?.vehicleId,
            },
          }}
          rowKey="baseId"
          buttonGroup={{
            showColumnSetting: false,
            showExport: false,
            showQuery: false,
            showReset: false,
            customize: {
              beforeQuery: (
                <span style={{lineHeight: '32px', marginRight: 8}}>
                  {operatingStatus.operatingStatusName}
                </span>
              ),
              afterQuery: (
                <Button onClick={() => tableStore.queryTable(tableName)}>
                  {f({id: 'tx000056', description: '刷新'})}
                </Button>
              ),
            },
          }}
          extraButtonGroup={
            <>
              <Button icon={<PlusOutlined/>} type="primary" loading={sendLoading} onClick={onSend}>
                {f({id: 'tx000055', description: '上传'})}
              </Button>
              <span style={{lineHeight: '32px', marginLeft: 8}}>{uploadStatusName}</span>
            </>
          }
          size="small"
        />
      </div>
    </Modal>
  )
}

export default VideoDownload
