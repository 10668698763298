import React, { useEffect } from 'react'
import { LineOutlined, ExpandOutlined } from '@ant-design/icons'
import useSetState from 'util/useSetState'
import { Icon } from 'components';
import { useIntl } from 'react-intl'
import { getGpsStatistic } from 'server/web/gps-statistic'
import { useInterval } from 'react-use';

interface TabBarProps {
  /** 最小化 */
  onShrinkClick: () => void
  /** 窗口化 */
  onNarrowClick: () => void
  /** 最大化 */
  onFullScreenClick: () => void
  hiddenIcon: number
}

export interface TabBarDataProps {
  /** 报警数 */
  alarmSum: number
  /** 离线车辆数 */
  offlineVehicleSum: number
  /** 上线率 */
  onlineRate: string
  /** 上线车辆数 */
  onlineVehicleSum: number
  /** 停车数 */
  parkingSum: number
  /** 行驶数 */
  runningSum: number
  /** 车辆数 */
  vehicleSum: number
}

interface MyState extends TabBarDataProps {
}

function TabBar(props: TabBarProps) {

  const { formatMessage: f } = useIntl()

  const [state, setState] = useSetState<MyState>({
    alarmSum: 0,
    offlineVehicleSum: 0,
    onlineRate: '0',
    onlineVehicleSum: 0,
    parkingSum: 0,
    runningSum: 0,
    vehicleSum: 0,
  })

  useEffect(() => {
    queryData()
  }, [])

  useInterval(() => {
    queryData()
  }, 30 * 1000)

  const queryData = async () => {
    const result = await getGpsStatistic()
    if (result) {
      setState({
        ...result.data,
        onlineRate: result.data.onlineRateDesc
      })
    }
  }

  const onShrinkClick = () => {
    props.onShrinkClick()
  }

  const onNarrowClick = () => {
    props.onNarrowClick()
  }

  const onFullScreenClick = () => {
    props.onFullScreenClick()
  }


  return (
    <div className='tbas-bar'>
      <div className='bar-info'>
        (
        <span className='bar-item'>车辆数: { state.vehicleSum }</span>
        <span className='bar-item'>在线: { state.onlineVehicleSum }</span>
        <span className='bar-item'>离线: { state.offlineVehicleSum }</span>
        <span className='bar-item'>在线率: { state.onlineRate }</span>
        <span className='bar-item'>行驶: { state.runningSum }</span>
        <span className='bar-item'>停车: { state.parkingSum }</span>
        <span className='bar-item'>报警: { state.alarmSum }</span>
        )
      </div>
      <div className='bar-tools'>
        {
          props.hiddenIcon !== 1 &&
          <LineOutlined onClick={ onShrinkClick } style={ { marginRight: 6 } } title={ f({ id: 'tx000018', description: '最小化' }) } />
        }
        {
          props.hiddenIcon !== 2 &&
          <Icon type='iconnarrow' onClick={ onNarrowClick } style={ { marginRight: 6 } } title={ f({ id: 'tx000019', description: '窗口化' }) } />
        }
        {
          props.hiddenIcon !== 3 &&
          <ExpandOutlined onClick={ onFullScreenClick } title={ f({ id: 'tx000020', description: '最大化' }) } />
        }
      </div>
    </div>
  )
}

export default TabBar
