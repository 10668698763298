import React, { useEffect, useRef, useReducer, CSSProperties, useState } from 'react'
import echarts, { EChartOption } from 'echarts/lib/echarts'
import { BampProps } from './interface'
import { createScript } from 'util/comm'
import { MapDarkStyle } from './theme'

// 引入dataset
import 'echarts/lib/component/dataset'
// 引入柱状图
import 'echarts/lib/chart/bar'
// 引入折线图
import 'echarts/lib/chart/line'
// 引入饼图
import 'echarts/lib/chart/pie'
// 引入带有起点和终点信息的线
import 'echarts/lib/chart/lines'
// 引入涟漪特效动画的散点
import 'echarts/lib/chart/effectScatter'
// 引入涟漪特效动画的散点
import 'echarts/lib/chart/scatter'
// 引入地图
import 'echarts/lib/chart/map'
import 'echarts/extension-src/bmap/bmap.js'
// 引入中国地图数据
import 'echarts/map/js/china'

// 引入提示框和标题组件
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/visualMap'
import { toLngLat } from 'components/map/util/comm'

export interface EchartsProps extends EChartOption {
  className?: string
  style?: CSSProperties
  bmap?: BampProps
  onCreate?: () => void
}

const Reducer = (state: any, action: any) => {
  const { type, data } = action

  switch (type) {
    case 'create':
      state = data
      return state
    case 'update':
      if (state) {
        state.resize()
      }
      return state
    case 'dispose':
      if (state) {
        state.dispose()
      }
      state = null
      return state
    default:
      return state
  }
}

const Index = (props: EchartsProps) => {
  const { className, onCreate, ...extra } = props

  const ehcartsElement: any = useRef()

  const [myChart, dispatch] = useReducer(Reducer, null)
  const _bmap: any = useRef()


  useEffect(() => {

    window.addEventListener('resize', resize)

    return () => {
      dispose()
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    const selfChart: any = echarts.init(ehcartsElement.current)
    if ('bmap' in props) {
      createScript(
        'https://api.map.baidu.com/api?v=2.0&ak=6YsMO6czdrUYPLP1b1THxEmMUXriXGxX&callback=onBMapCallback',
      ).then(() => {
        selfChart.setOption({ ...extra }, true)
        var bmap = selfChart.getModel().getComponent('bmap').getBMap()
        _bmap.current = bmap
        bmap.setMapStyle({ styleJson: MapDarkStyle })
        if (onCreate) {
          onCreate()
        }

        dispatch({ type: 'create', data: selfChart })
      })
    } else {
      selfChart.setOption({ ...extra })
      if (onCreate) {
        onCreate()
      }
      dispatch({ type: 'create', data: selfChart })
    }
  }, [ehcartsElement.current])

  useEffect(() => {
    if (myChart) {
      myChart.setOption({ ...extra })
      if (extra.bmap && extra.bmap.isViewport && _bmap.current) {
        if (extra.bmap?.viewport && extra.bmap.viewport.length) {
          _bmap.current.setViewport(extra.bmap.viewport?.map(item => toLngLat(item, 'baidu')))
        }
      }
    }
  }, [props.series])

  const resize = () => {
    dispatch({ type: 'update' })
  }

  const dispose = () => {
    dispatch({ type: 'dispose' })
  }

  return (
    <div
      ref={ ehcartsElement }
      className={ className }
      style={ { width: '100%', height: '100%', ...props.style } }
    >

    </div>
  )
}

export default Index
